import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { usePopper } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'

import Layout from '../../layout/live-coding-layout'
import { SEO, Benefits, Image, Feature, Quantity } from '../../components'
import { decodeQueryString, canUseWindow } from '../../utils'

import './live-coding.scss'

import SDKsIcon from '../../assets/icons/sdks.svg'
import CloudIcon from '../../assets/icons/cloud.svg'
import TechSupportIcon from '../../assets/icons/tech-support.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import GitHubIcon from '../../assets/icons/github.svg'
import LiveChatIcon from '../../assets/icons/livechat-icon.svg'
import OgImage from '../../assets/images/livechat-live-coding-og.png'

const HoverMe = ({ children, desc, setOverlay }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [visible, setVisible] = useState(false)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleClick = e => {
    e.preventDefault()
    setVisible(true)
    setOverlay(true)
  }

  const handleMouseOver = () => {
    setOverlay(true)
    setVisible(true)
  }

  const handleMouseOut = () => {
    setOverlay(false)
    setVisible(false)
  }

  return (
    <>
      <a
        href="#details"
        ref={setReferenceElement}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`hoverMe ${visible && 'hovered'}`}
      >
        <span className="u-text-mark">{children}</span>
      </a>

      <AnimatePresence>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popper-wrapper"
          >
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                rotateX: 0,
              }}
              initial={{
                y: 10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: 4,
              }}
              exit={{
                y: -10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: -4,
              }}
              transition={{ ease: 'easeOut', duration: 0.15 }}
              className={'popperBox'}
            >
              <div className="u-px-xl">{desc}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 870 })

  const [overlay, setOverlay] = useState(initialState)

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="March 19th, 2021"
      headerCta={{
        label: 'Join discord',
        url: 'https://discord.gg/gRzwSaCxg4',
      }}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Leverage your skills in the area of web app authorization and have fun fun!"
        image={OgImage}
      />
      <section className="u-text-center u-Pb-xl live-coding-bg">
        <Image
          type="liveCodingCover4"
          alt="live-coding"
          className="u-mb-lg"
          imgStyles={{ width: '100vw', height: '85%' }}
        />
        <h2 className="u-text-p3 u-mt-0 u-mb-sm">
          Authorizing web apps in LiveChat
        </h2>
        <div className="u-text-p5 u-mt-3xl intro">
          Take part in a{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>2-hour session</strong>
                </p>
                <p>
                  Join us for a live code-along session. Let's hang out with the
                  Developer Program team members and other developers.
                </p>
              </>
            }
          >
            live coding session
          </HoverMe>{' '}
          organized by the LiveChat Developer Program. Together, we'll go
          through LiveChat authorization for web applications. Leverage your{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Master LiveChat authorization</strong>
                </p>
                <p>
                  After the session, you'll be able to confidently implement an
                  auth flow in your web application.
                </p>
              </>
            }
          >
            skills
          </HoverMe>
          , make new{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Let's meet on Discord</strong>
                </p>
                <p>
                  We'll host the event on Discord. You'll have a chance to join
                  the community of developers and build strong connections.
                </p>
              </>
            }
          >
            connections
          </HoverMe>{' '}
          and win{' '}
          <HoverMe
            setOverlay={setOverlay}
            desc={
              <>
                <p className="u-mb-xs">
                  <strong>Kickstart your journey in Dev Program</strong>
                </p>
                <p>
                  Finish the task and submit a pull request with the solution.
                  The first 10 developers will see $50 landing in their
                  Developer Accounts.
                </p>
              </>
            }
          >
            prizes
          </HoverMe>
          .
        </div>
        <div className="u-text-p4 u-mt-2xl u-mb-3xl intro">
          {' '}
          <strong>03/19/2021, 2pm CET </strong>
        </div>
        <a
          href="https://discord.gg/gRzwSaCxg4"
          target="_blank"
          rel="noopener noreferrer"
          className="c-btn v--livechat u-text-white u-text-p5-bold"
        >
          Join Discord
        </a>
      </section>
      <section className="u-mb-sm u-Pt-xl">
        <h2 className="u-text-p2 u-Mb-md u-text-center">Prizes</h2>
        <Feature
          title={
            <Fragment>Solve a programming task and win a gift card</Fragment>
          }
          description="We'll give you a coding task to solve after the session. For the first 10 developers who submit a pull request with the correct solution, $50 will land in their Developer Account in Developer Program. We'll share more details about the task during the event. Stay tuned!"
          component={
            <Quantity
              value="10 x $50"
              description="to start in Developer Program"
            />
          }
        />
      </section>
      <section className="o-container u-Mb-xl o-columns v--two">
        <div className="o-container">
          <h2 className="u-text-p4">Event rules</h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              The session will be hosted on{' '}
              <a href="https://discord.gg/gRzwSaCxg4" target="blank_">
                Discord
              </a>
              , where we'll share all details.
            </li>
            <li className="u-mb-sm">
              Participants can win a gift card with the value of $50. The funds
              will be transferred to the winners' accounts in{' '}
              <a href="/console/" target="_blank" rel="noopener noreferrer">
                Developer Console
              </a>
              .
            </li>
            <li className="u-mb-sm">
              To withdraw the funds, winners need to have published a paid app
              on the{' '}
              <a
                href="https://www.livechat.com/marketplace"
                target="_blank"
                rel="noopener noreferrer"
              >
                LiveChat Marketplace
              </a>
              .
            </li>
            <li className="u-mb-sm">
              How to win the prize?
              <ul>
                <li>
                  Create an account in{' '}
                  <a href="/console/" target="_blank" rel="noopener noreferrer">
                    Developer Console
                  </a>
                  .
                </li>
                <li>Take part in the live coding session.</li>
                <li>Solve the task given during the session.</li>
                <li>Submit a pull request with the solution.</li>
              </ul>
              <br />
              The first 10 people who submit the correct solution will win the
              prize.{' '}
              <a
                href="https://developers.livechat.com/developer-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developer Terms
              </a>{' '}
              apply.
            </li>
          </ol>
        </div>

        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">You'll learn</span>
          </h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              How to configure the <strong>Agent App Widgets</strong> and{' '}
              <strong>App Authorization</strong> building blocks in Developer
              Console.
              <ul>
                <li>
                  <a
                    href="https://developers.livechat.com/docs/getting-started/app-guides/app-authorization/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
                  >
                    {' '}
                    App authorization tutorials
                  </a>
                </li>
                <li>
                  <a
                    href="https://developers.livechat.com/docs/getting-started/app-guides/agent-app-widgets/"
                    className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Agent App Widgets tutorial
                  </a>
                </li>
              </ul>
            </li>
            <li className="u-mb-sm">
              How to implement an <strong>authorization flow</strong> in your{' '}
              <strong>web application</strong>.
              <ul>
                <li>
                  <a
                    href="https://developers.livechat.com/docs/authorization/authorizing-api-calls/#implicit-grant"
                    className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Implicit grant flow docs
                  </a>
                </li>
              </ul>
            </li>
            <li className="u-mb-sm">
              How to use <strong>Accounts SDK</strong> and{' '}
              <strong>Sign in with LiveChat</strong>.
              <ul>
                <li>
                  <a
                    href="https://developers.livechat.com/docs/authorization/sign-in-with-livechat/"
                    className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Sign in with LiveChat docs
                  </a>
                </li>
              </ul>
            </li>
            <li className="u-mb-sm">
              How to send <strong>rich messages</strong> in the chat.
              <ul>
                <li>
                  <a
                    href="https://developers.livechat.com/docs/extending-chat-widget/rich-messages/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
                  >
                    {' '}
                    Rich messages docs
                  </a>
                </li>
              </ul>
            </li>
            It'll also be an incredible opportunity to clarify any doubts around
            app authorization or monetization you might have.
          </ol>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">About the host</span>
          </h2>
          <div className="o-feature u-mb-md">
            <div
              className="u-mb-xs u-flex"
              style={{
                justifyContent: isWide ? 'start' : 'center',
                alignItems: isWide ? 'start' : 'center',
              }}
            >
              <Image
                type="jakubSikora"
                className="u-round"
                wrapperStyles={{
                  width: '180px',
                }}
              />
            </div>
            <div
              class="u-ml-auto u-mr-auto u-flex u-flex-col"
              style={{
                textAlign: isWide ? 'left' : 'center',
              }}
            >
              <p className="u-text-p5 u-mb-md">Jakub Sikora</p>
              <p className="u-text-p10 u-text-gray-600">
                Frontend Lead,
                <br />
                Developer Program
              </p>
              <div className="o-column v--three v--sparse">
                <a
                  href="https://twitter.com/JakubSikoraJS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/in/jbsikora/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <LinkedinIcon />
                </a>
                <a
                  href="https://github.com/jakubsikora"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300"
                >
                  <GitHubIcon />
                </a>
              </div>
            </div>
          </div>
          <p className="u-text-p6 u-text-gray-800">
            <a
              href="https://www.livechat.com/team/#jakub-sikora"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jakub Sikora
            </a>{' '}
            is a software engineer with over 11 years of experience in web
            development. He's the frontend lead in the LiveChat Developer
            Program team. He holds weekly programming workshops for his team
            members.
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">Who we are</h2>
          <p className="u-text-p6 u-text-gray-800">
            Behind LiveChat, Inc. there is a team of{' '}
            <a
              href="https://www.livechat.com/team/"
              target="_blank"
              rel="noopener noreferrer"
            >
              passionate people
            </a>{' '}
            building online customer service software with online chat, help
            desk software, chatbot, and web analytics capabilities.{' '}
          </p>
          <p className="u-text-p6 u-text-gray-800">
            With a suite of four products (
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveChat
            </a>
            ,{' '}
            <a
              href="https://www.chatbot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatBot
            </a>
            ,{' '}
            <a
              href="https://www.helpdesk.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              HelpDesk
            </a>
            ,{' '}
            <a
              href="https://www.knowledgebase.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              KnowledgeBase
            </a>
            ) and their powerful APIs, we power customer communication for
            31,000 companies in 150 countries.
          </p>
          <p className="u-text-p6 u-text-gray-800">
            <Link to="/">LiveChat Platform</Link> is a range of products and
            services that can be used to build a variety of communication tools
            for businesses. Our <Link to="/">Developer Program</Link> and{' '}
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marketplace{' '}
            </a>
            create an open ecosystem for developers, partners, and customers.
          </p>
        </div>
      </section>

      <section className="u-bg-black2 u-Py-xl u-Mt-2xl u-Mb-md">
        <h2 className="u-text-p3 u-Mb-md u-text-center">Before you go...</h2>
        <Benefits
          content={[
            {
              icon: SDKsIcon,
              label: 'Part 1: Building apps 101',
              description: (
                <>
                  <a
                    href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watch part 1
                  </a>{' '}
                  of the live coding session. We'll pick up from where we left
                  off last time and extend the application.
                </>
              ),
            },
            {
              icon: TechSupportIcon,
              label: 'Part 2: Authorizing web apps',
              description:
                "Authorization doesn't have to be difficult and our live coding session is about to prove that!",
            },
            {
              icon: CloudIcon,
              label: 'Part 3: Authorizing server-side apps',
              description:
                "We're getting ready to invite you to the live coding part 3. It'll be focused on authorizing server-side apps.",
            },
          ]}
        />
        <div className="u-text-center">
          <h3 className="u-text-p5 u-Mb-md u-text-center">
            The event will be hosted on Discord.
            <br />
          </h3>
          <a
            href="https://discord.gg/gRzwSaCxg4"
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            Join Discord
          </a>
        </div>
      </section>
      <section className="u-text-center u-My-xl">
        <LiveChatIcon />
        <p className="u-text-p8-bold u-mt-sm outro">
          Live coding session by the <Link to="/">LiveChat Platform</Link>
        </p>
        <p className="u-text-p8 outro">
          Our vision is to help innovators build the future of the
          communication. Sounds interesting?{' '}
          <a href="https://www.livechat.com/careers/">Join us</a>!
        </p>
      </section>
    </Layout>
  )
}

HoverMe.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setOverlay: PropTypes.func,
}

export default LiveCoding
